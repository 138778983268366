import React from 'react';
import { graphql } from 'gatsby';

import DefaultLayout from '../layouts';
import Tile from '../components/Tile/Tile';

const Blog = ({
  data: {
    allContentfulBlogPost: { edges },
  },
}) => {
  const metaData = {
    title: 'Blog',
  };
  return (
    <DefaultLayout metaData={metaData}>
      <div className="content">
        <div className="container container--medium">
          <h1 className="text-center">
            <span className="color-brand">Māo</span>Blog
          </h1>
          <div className="grid">
            {edges.map(post => (
              <>
                <Tile
                  key={post.node.contentful_id}
                  thumb={post.node.heroImage.file.url}
                  title={post.node.title}
                  {...post.node}
                />
              </>
            ))}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Blog;

export const query = graphql`
  {
    allContentfulBlogPost {
      edges {
        node {
          heroImage {
            file {
              url
            }
          }
          contentful_id
          title
          slug
          description {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`;
