import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import styles from './styles.module.scss';

const Tile = ({ thumb, title, slug, description }) => {
  return (
    <div className={cx('grid__tile', styles.base)}>
      <Link to={`/blog/${slug}`} className={styles.image}>
        <img src={thumb} />
      </Link>
      <h2 className="h4">
        <Link to={`/blog/${slug}`} className={styles.title}>
          {title}
        </Link>
      </h2>
      <div
        dangerouslySetInnerHTML={{
          __html: description.childMarkdownRemark.excerpt,
        }}
      />
      <div className={cx('text-right', styles.more)}>
        <Link to={`/blog/${slug}`}>Read more &raquo;</Link>
      </div>
    </div>
  );
};

export default Tile;
